import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Suspense } from 'react';
import { SessionContextType } from '@/components/context/SessionContext';

export type TRouterContext = SessionContextType & { queryClient };

export const Route = createRootRouteWithContext<TRouterContext>()({
  component: () => (
    <>
      <NavBar />
      <main className='min-h-full/ relative flex w-full grow flex-col'>
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      <TanStackRouterDevtools />
    </>
  )
});

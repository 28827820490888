'use client';

import api from '@/lib/api';
import { useQueryClient } from '@tanstack/react-query';
import { Link, createFileRoute, useNavigate } from '@tanstack/react-router';
import { HTTPError } from 'ky';
import toast from 'react-hot-toast';

// import { useFormState } from 'react-dom';
// import action from './action';

const initialState = {
  error: ''
};

export const Route = createFileRoute('/_auth/signup')({ component: Signup });

export default function Signup() {
  const navigate = useNavigate();
  // const [state, formAction] = useFormState(action, initialState);
  const queryClient = useQueryClient();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submit');

    const data = new FormData(e.target);
    const email = data.get('email');
    const password = data.get('password');
    // formAction({ type: 'submit', username, password });

    try {
      const promise = api.post('auth/signup', { json: { email: email, password } });
      toast.promise(promise, {
        loading: 'Creating account...',
        success: 'Account created successfully',
        error: 'Error creating account'
      });
      const res = await promise;
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ['session'] });
        // window.location.href = '/dashboard';
        // navigate({ to: '/home' });
      }
    } catch (err) {
      console.log(err);
      if (err instanceof HTTPError) {
        toast.error((await err.response.json()).error || 'Unknown error');
      } else {
        toast.error('Error creating account');
      }
    }
  };
  return (
    <div className=''>
      <h2 className='card-title'>Create Account to get Started!</h2>
      {/* {state?.error} */}
      <form onSubmit={handleSubmit} className=''>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Email</span>
          </label>
          <input
            //   onChange={(e) => {
            //     setEmail(e.target.value);
            //   }}
            type='email'
            placeholder='Email'
            name='email'
            id='email'
            className='input input-bordered'
            required
          />
        </div>

        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Password</span>
          </label>
          <input
            //   onChange={(e) => setPassword(e.target.value)}

            type='password'
            name='password'
            placeholder='Password'
            id='password'
            className='input input-bordered'
            required
          />
        </div>
        <div className='form-control'>
          <button type='submit' className='btn btn-secondary mt-4'>
            Submit
          </button>
        </div>
      </form>
      <span className='float-right mt-4'>
        <Link className='link link-accent' href='/auth/login'>
          Login
        </Link>
      </span>
    </div>
  );
}

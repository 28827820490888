'use client';

import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/')({ component: Home });
export default function Home() {
  return (
    <div className='text-black dark:text-green-500'>
      root page
      <button
        type='button'
        onClick={() => {
          throw new Error('Sentry Frontend Error');
        }}
      >
        Throw error
      </button>
      this is a test
    </div>
  );
}

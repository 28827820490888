'use client';

import api from '@/lib/api';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import { HTTPError } from 'ky';
import toast from 'react-hot-toast';
import { FormEvent } from 'react';

// import { Input } from '@mui/base';
// import { Input as Input2 } from '@mui/joy';

// import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';

// import { cookies } from 'next/headers';
// import Link from 'next/link';
// import action from './action';

export const Route = createFileRoute('/_auth/login')({ component: Login });

export default function Login() {
  const queryClient = useQueryClient();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    // formAction({ type: 'submit', username, password });

    try {
      const promise = api.post('auth/login', { json: { email: email, password } });
      const res = await toast.promise(promise, {
        loading: 'Logging in...',
        success: 'Logged in successfully',
        error: (err: HTTPError) => {
          console.debug(err.response);
          if (err.response.status === 406) {
            return 'Invalid credentials';
          }

          return err.response.statusText || 'Error logging in';
        }
      });
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ['session'] });
        // window.location.href = '/dashboard';
        // navigate({ to: '/home' });
      }
    } catch (err) {
      console.warn(err);
    }
  };
  // const [state, formAction] = useActionState(action, initialState);
  return (
    <div className=''>
      <h2 className='card-title'>Login to your account!</h2>

      {/* {state?.error} */}
      <form onSubmit={handleSubmit} className=''>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Email</span>
          </label>
          <input
            //   onChange={(e) => {
            //     setEmail(e.target.value);
            //   }}
            type='email'
            placeholder='Email'
            name='email'
            className='input input-bordered'
            required
          />
        </div>

        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Password</span>
          </label>
          <input
            //   onChange={(e) => setPassword(e.target.value)}

            type='password'
            name='password'
            placeholder='Password'
            className='input input-bordered'
            required
          />
        </div>
        <div className='form-control'>
          <button type='submit' className='btn btn-secondary mt-4'>
            Submit
          </button>
        </div>
      </form>
      <span className='float-right mt-4'>
        <Link className='link link-accent' to='/signup'>
          Sign-up
        </Link>
      </span>
    </div>
  );
}

'use client';

import api from '@/lib/api';
import { Category } from '@repo/database';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

const getCategories = async () => {};

export const Route = createFileRoute('/_app/account/settings/_layout/drinks')({
  component: DrinksPage
});

export default function DrinksPage() {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null); // [1
  const { data: categories, status: categoriesStatus } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const data: Category[] = await api.get('categories/all').json();
      setSelectedCategory(data[0]?.id ?? null);
      return data;
    }
  });
  console.log(categories);
  //todo render ingredients etc

  return (
    <div className='flex flex-col items-center'>
      <h1 className='my-6 text-2xl'>Manage ingredients</h1>
      <div className='my-4 flex w-full flex-col justify-around md:flex-row'>
        <section className='mx-4 grow bg-neutral-800 shadow-inner shadow-black'>
          <h2 className='bg-neutral-400 text-black'>Categories</h2>
          <ul className='shadow-inner/ h-40 overflow-auto p-2 shadow-black'>
            {categoriesStatus === 'pending' && <li>Loading...</li>}
            {categories && categories.length > 0
              ? categories?.map((category) => (
                  <>
                    <li key={category.id}>{category.name}</li>
                    <li key={category.id}>{category.name}</li>
                  </>
                ))
              : ''}
          </ul>
        </section>
        <section className='mx-4 grow bg-neutral-800 shadow-inner shadow-black'>
          <h2>Types</h2>
          <div>
            <ul>
              <li>vodka</li> <li>mixers</li> <li>gins</li>
            </ul>
          </div>
        </section>
        <section className='mx-4 flex grow flex-col bg-blue-100/25'>
          <div>
            <h2>Flavours</h2>
          </div>
          <div>
            <h2>Variants</h2>
          </div>
        </section>
      </div>
    </div>
  );
}

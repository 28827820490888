import ky from 'ky';
import * as sentry from '@sentry/react';

const url = import.meta.env.VITE_API_URL;

export default ky.extend({
  prefixUrl: url,
  credentials: 'include',
  hooks: {
    beforeError: [
      async (error) => {
        sentry.captureException(error);
        return error;
      }
    ]
  }
});
